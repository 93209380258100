import React, { useState, FunctionComponent } from 'react';
import styled from '@emotion/styled';
import Colours from '../Styles/Colours';
import ImageUploader from './ImageUploader';
import MyDropdown from './MyDropdown';
import { css } from 'emotion';
import Axios from 'axios';
import Loader from 'react-loader-spinner';

const Container = styled.div`
  padding: 2rem;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  border-top: 5px;
  border-color: white;
  border-top-color: ${Colours.disabledButton};
  border-style: solid;
`;

const Tip = styled.textarea`
  padding: 1rem;
  border-radius: 6px;
  border-color: ${Colours.disabledButton};
  border-width: 2px;
  border-style: solid;
  margin-top: 1rem;
  font-weight: 600;
  width: 100%;
  color: ${Colours.default};
  min-height: 12rem;
  ::placeholder {
    color: ${Colours.defaultRgba(0.3)};
  }
`;

const TextInput = styled.input`
  padding: 10px;
  border-radius: 6px;
  border-color: ${Colours.disabledButton};
  border-width: 2px;
  border-style: solid;
  color: ${Colours.default};
  ::placeholder {
    color: ${Colours.defaultRgba(0.3)};
  }
`;

const Button = styled.button`
  padding: 1.5rem;
  background-color: ${Colours.default};
  color: white;
  border-radius: 12px;
  border: 0;
  cursor: pointer;

  :disabled {
    background-color: ${Colours.disabledButton};
    color: ${Colours.defaultRgba(0.5)};
    cursor: auto;
  }
`;

const Link = styled.a`
  color: ${Colours.default};
  :visited {
    color: ${Colours.default};
  }
`;

interface Props {
  token: string;
  firstName: string;
  handleSubmit: () => void;
}

interface Payload {
  first_name: string;
  relationship: string;
  description: string;
  photo_key: string;
}

const Form: FunctionComponent<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<Payload>({
    first_name: '',
    relationship: 'friend',
    description: '',
    photo_key: '',
  });

  const handleChange = (property: string) => (value: string) => {
    setPayload({ ...payload, [property]: value });
  };

  const disabled = Object.values(payload)
    .map((value) => !!value)
    .includes(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const result = await Axios.post(
        `${process.env.REACT_APP_ENDPOINT}contribution_requests/${props.token}`,
        payload
      );
      if (result.status === 201) {
        props.handleSubmit();
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Container>
      <label htmlFor="description">
        What do you want to say about {props.firstName}?
      </label>
      <Tip
        placeholder={`Lost for words? Why not share what you like most about ${props.firstName}, a funny story or simply share why other people might want to date them!`}
        value={payload.description}
        onChange={(e) => handleChange('description')(e.target.value)}
        name="description"
        id="description"
      />
      <div
        className={css`
          margin-top: 2rem;
        `}
      >
        <label
          htmlFor="firstname"
          className={css`
            display: flex;
            flex-direction: column;
          `}
        >
          Your first name
          <TextInput
            name="firstname"
            id="firstname"
            placeholder="First name"
            onChange={(e) => handleChange('first_name')(e.target.value)}
            value={payload.first_name}
          />
        </label>
      </div>
      <div
        className={css`
          margin-top: 2rem;
        `}
      >
        <label>Your relation to {props.firstName}</label>
        <MyDropdown handleChange={handleChange('relationship')} />
      </div>
      <ImageUploader
        token={props.token}
        handleChange={handleChange('photo_key')}
      />
      <div
        className={css`
          display: flex;
          flex-direction: column;
          margin-top: 5rem;
          margin-bottom: 3rem;
        `}
      >
        <p
          className={css`
            margin-bottom: 3rem;
            font-size: 1.4rem;
            color: ${Colours.defaultRgba(0.5)};
          `}
        >
          By continuing you agree to The Intro’s{' '}
          <Link href="https://theintro.com/terms">Terms & Conditions</Link> and{' '}
          <Link href="https://theintro.com/privacy">Privacy Policy</Link>. You
          accept that this information will remain on {props.firstName}’s
          profile until they choose to remove it or delete their account.
        </p>

        {loading ? (
          <Button onClick={() => {}}>
            <Loader type="TailSpin" color="white" height={20} width={20} />
          </Button>
        ) : (
          <Button disabled={disabled} onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Form;
