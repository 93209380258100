import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import styled from '@emotion/styled';
import Colours from '../Styles/Colours';
import Avatar from '../Components/Avatar';
import Accordion from '../Components/Accordion';
import Form from '../Components/Form';
import Logo from '../assets/logo.png';
import ContributionCompleted from './ContributionCompleted';

interface Payload {
  first_name: string;
  picture_url: string;
}

const Container = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  padding: 20px;
  background-color: ${Colours.default};
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  color: white;
  display: flex;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 20px;
  text-align: center;
  line-height: 1;
  font-weight: 400;
  span {
    font-weight: 700;
  }
`;

const Content = styled.div`
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Home = (props: any) => {
  const token = window.location.pathname.slice(1);
  const [firstName, setFirstName] = useState<string>();
  const [pictureUrl, setPictureUrl] = useState<string>();
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (!firstName || !pictureUrl) {
      axios
        .get<any, AxiosResponse<Payload>>(
          `${process.env.REACT_APP_ENDPOINT}contribution_requests/${token}`
        )
        .then((result) => {
          if (result.data) {
            setFirstName(result.data.first_name);
            setPictureUrl(result.data.picture_url);
          }
        });
    }
  }, [token, firstName, pictureUrl]);

  const handleSubmit = () => {
    setFinished(true);
  };

  if (!firstName || !pictureUrl) return null;

  if (finished) {
    return <ContributionCompleted firstName={firstName} />;
  }

  return (
    <Container data-testid="form">
      <Header>
        <img src={Logo} alt="Intro logo" style={{ maxWidth: '80%' }} />
      </Header>
      <Content>
        <Avatar url={pictureUrl} />
        <Title>
          Contribute to <span>{firstName}'s</span> dating profile
        </Title>
        <Accordion firstName={firstName} />
        <Form token={token} firstName={firstName} handleSubmit={handleSubmit} />
      </Content>
    </Container>
  );
};

export default Home;
