import React, { useState, FunctionComponent } from 'react';
import 'react-dropdown-now/style.css';
import Dropdown from 'react-dropdown-now';
import { css } from 'emotion';
import Colours from '../Styles/Colours';
import DownArrow from '../assets/chevron-down.svg';

interface Props {
  handleChange: (key: string) => void;
}

const MyDropdown: FunctionComponent<Props> = (props) => {
  const options = [
    { value: 'friend', label: 'Friend' },
    { value: 'ex', label: 'Ex' },
    { value: 'brother', label: 'Brother' },
    { value: 'sister', label: 'Sister' },
    { value: 'mother', label: 'Mother' },
    { value: 'father', label: 'Father' },
  ];

  const [selected, setSelected] = useState('friend');

  return (
    <Dropdown
      options={options}
      onChange={(selection) => {
        setSelected(selection.value);
        props.handleChange(selection.value);
      }}
      value={selected}
      placeholder="Select an option"
      controlClassName={css`
        border-radius: 6px;
        border-color: ${Colours.disabledButton};
        color: ${Colours.default};
        cursor: pointer;
      `}
      menuClassName={css`
        border-radius: 6px;
        margin-top: 10px;
        border-color: ${Colours.disabledButton};
        color: red;
        max-height: 100rem;
        .Dropdown-option {
          color: ${Colours.default};
        }
        .is-selected {
          color: white;
          background-color: ${Colours.default};
        }
      `}
      arrowOpen={
        <img
          src={DownArrow}
          alt="downArrow"
          className={css`
            height: 15px;
            width: 15px;
            display: block;
            margin-top: -ceil(2.5);
            position: absolute;
            right: 10px;
            top: 14px;
            transform: rotate(180deg);
          `}
        />
      }
      arrowClosed={
        <img
          src={DownArrow}
          alt="downArrow"
          className={css`
            height: 15px;
            width: 15px;
            display: block;
            margin-top: -ceil(2.5);
            position: absolute;
            right: 10px;
            top: 14px;
          `}
        />
      }
    />
  );
};

export default MyDropdown;
