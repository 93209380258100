import React, { useState, FunctionComponent } from 'react';
import styled from '@emotion/styled';
import Colours from '../Styles/Colours';
import Axios, { AxiosResponse } from 'axios';
import Loader from 'react-loader-spinner';
import { css } from 'emotion';

const ImageLabel = styled.label`
  width: 32.5rem;
  height: 32.5rem;
  max-width: 100%;
  background-color: ${Colours.disabledButton};
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: none;
  cursor: pointer;
`;

const ImageContainer = styled.img`
  width: 32.5rem;
  height: 32.5rem;
  border-radius: 0.6rem;
  max-width: 100%;
  object-fit: cover;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const ImageSpinnerContainer = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Circle = styled.div`
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  background-color: ${Colours.default};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  display: flex;
  flex: 1;
  width: 100px;
  height: 100px;
`;

interface Props {
  token: string;
  handleChange: (key: string) => void;
}

interface Payload {
  url: string;
  key: string;
}

interface ImageFile {
  file: File;
  url: string;
}

const getFiletype = (fileName: string) => {
  return fileName.split('.').reverse()[0];
};

const ImageUploader: FunctionComponent<Props> = (props) => {
  const [file, setFile] = useState<ImageFile>();
  const [loading, setLoading] = useState(false);

  const uploadFile = async (uploadFile: ImageFile) => {
    setLoading(true);
    try {
      const fileType = getFiletype(uploadFile.file.name);
      const result = await Axios.get<any, AxiosResponse<Payload>>(
        `${process.env.REACT_APP_ENDPOINT}contribution_requests/${props.token}/photo_upload_url?extension=${fileType}`
      );

      if (result.data) {
        const options = {
          headers: {
            'Content-Type': uploadFile.file.type,
          },
        };

        const s3Result = await Axios.put(
          result.data.url,
          uploadFile.file,
          options
        );
        if (s3Result.status === 200) {
          setLoading(false);
          props.handleChange(result.data.key);
        }
      }
    } catch (e) {
      setLoading(false);
      setFile(undefined);
    }
  };

  return (
    <Container>
      <div id="photosTitleId">Your photo</div>
      <FileInput
        aria-labelledby="photosTitleId addPhotoId"
        data-testid="image-form"
        name="file"
        id="file"
        type="file"
        onChange={(e) => {
          e.preventDefault();
          setLoading(true);

          const reader = new FileReader();
          const file = e.target.files![0]!;

          reader.onloadend = () => {
            if (
              file &&
              (file.type.includes('jpeg') || file.type.includes('png'))
            ) {
              setFile({ url: reader!.result as string, file });
              uploadFile({ url: reader!.result as any, file });
            }
          };
          reader.readAsDataURL(file);
        }}
      />
      {file ? (
        <ImageSpinnerContainer>
          <ImageContainer src={file.url} alt="image-preview" />
          {loading ? (
            <SpinnerContainer
              data-testid="spinner"
              style={{ position: 'absolute' }}
            >
              <Loader
                type="TailSpin"
                color={Colours.default}
                height={100}
                width={100}
                style={{ position: 'absolute' }}
                visible={loading}
              />
            </SpinnerContainer>
          ) : null}
        </ImageSpinnerContainer>
      ) : (
        <ImageLabel htmlFor="file" data-testid="add-image-btn" id="addPhotoId">
          <Circle>
            <span
              className={css`
                margin-top: -5px;
              `}
            >
              +
            </span>
          </Circle>
        </ImageLabel>
      )}
    </Container>
  );
};

export default ImageUploader;
