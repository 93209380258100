import React, { FunctionComponent } from 'react';
import Logo from '../assets/logo.png';
import styled from '@emotion/styled';
import Colours from '../Styles/Colours';
import CheckMarkCircle from '../assets/checkbox-outline.svg';
import InfoIcon from '../assets/info-icon.svg';
import IOS from '../assets/iOS.svg';
import Android from '../assets/Android.svg';
import { css } from 'emotion';

const BlueContainer = styled.div`
  padding-top: 2rem;
  width: 100%;
  height: '50vh';
  display: flex;
  background-color: ${Colours.default};
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Arc = styled.div`
  background-color: #fff;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  z-index: 0;
  height: 15vh;
  top: -1px;
  ::before {
    content: '';
    width: 300vw;
    height: 300vw;
    border-radius: 150vw;
    background-color: #001a70;
    position: absolute;
    bottom: 30px;
    left: -100vw;
    z-index: 0;
  }
  @media (min-width: 1100px) {
    height: 30vh;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 1024px;
  align-self: center;
`;

const SubTitle = styled.h3`
  padding: 0 6rem 0 6rem;
  color: white;
  margin-top: 2rem;
  text-align: center;
  font-weight: 400;
`;

interface Props {
  firstName: string;
}

const storeImagesClassName = css`
  max-width: 90%;
`;

const ContributionCompleted: FunctionComponent<Props> = ({ firstName }) => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        justify-content: stretch;
      `}
    >
      <BlueContainer>
        <img
          src={Logo}
          alt="Intro logo"
          style={{ objectFit: 'contain', maxWidth: '40%' }}
        />
        <img
          src={CheckMarkCircle}
          alt="Submittion confirmation"
          style={{ marginTop: 60 }}
        />
        <h2 style={{ color: 'white' }}>Submitted</h2>
        <SubTitle>
          Your contribution will now appear on {firstName}'s profile
        </SubTitle>
      </BlueContainer>
      <Arc />
      <BottomContainer>
        <div
          className={css`
            display: flex;
            flex-direction: row;
            margin-bottom: 2rem;
          `}
        >
          <img
            src={InfoIcon}
            alt="section icon"
            className={css`
              margin-right: 1rem;
            `}
          />
          <h4
            className={css`
              margin-top: 5px;
            `}
          >
            Why not try The Intro yourself?
          </h4>
        </div>
        <p>
          When you match with members on The Intro, you skip the small talk, and
          meet within a week!
        </p>
        <div
          className={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 6rem;
          `}
        >
          <a href="https://theintro.app.link/ios">
            <img
              src={IOS}
              alt="ios download btn"
              className={storeImagesClassName}
            />
          </a>
          <a href="https://theintro.app.link/android">
            <img
              src={Android}
              alt="android download btn"
              className={storeImagesClassName}
            />
          </a>
        </div>
      </BottomContainer>
    </div>
  );
};

export default ContributionCompleted;
