import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';

interface Props {
  url: string;
}

const Container = styled.div`
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  margin-top: 4rem;
  margin-bottom: 2rem;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const Avatar: FunctionComponent<Props> = (props) => {
  return (
    <Container>
      <Image alt="Your friend profile image" src={props.url} />;
    </Container>
  );
};

export default Avatar;
