export default {
  default: '#001A70',
  defaultRgba: (opacity: number | string) => `rgba(0,26,112, ${opacity})`,
  accentText: '#8BFFF2',
  textInputBackground: '#7129b8',
  textInputPlaceholder: '#b793db',
  disabledButton: '#F0F1F5',
  mutedButton: 'white',

  darkPurple: '#443e5c',
  mediumPurple: '#6f5b91',
  lightPurple: '#c68dfc',
  greyBlue: '#617885',
  lightGrey: '#F5F5F5',
  mediumGrey: '#c1becb',
  mediumDarkGrey: '#6D6D6D',
  darkGrey: '#535353',
  errorRed: '#FF0000',
  orange: '#FCC63C',
  softOrange: '#fbc54b',
  grey: '#ECECEC',
};
