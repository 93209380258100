import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Global, css } from '@emotion/core';
import emotionNormalize from 'emotion-normalize';
import Colours from './Styles/Colours';

ReactDOM.render(
  <React.StrictMode>
    <Global
      styles={css`
        ${emotionNormalize}
        html {
          box-sizing: border-box;
          font-size: 62.5%;
          height: 100%;
        }
        *,
        *::after,
        *::before {
          margin: 0;
          padding: 0;
          box-sizing: inherit;
        }
        body {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
          line-height: 1.7;
          font-size: 1.6rem;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          height: 100%;
          background-color: white;
          color: ${Colours.default};
        }
      `}
    />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
