import React, { useState, FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import Colours from '../Styles/Colours';
import CheckMark from '../assets/checkmark.svg';
import DownArrow from '../assets/chevron-down.svg';

const Container = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PressableHeader = styled.button`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2rem;
  background-color: white;
  border: none;
  -webkit-box-shadow: 0px -7px 92px -15px rgba(0, 26, 112, 0.25);
  -moz-box-shadow: 0px -7px 92px -15px rgba(0, 26, 112, 0.25);
  box-shadow: 0px -7px 92px -15px rgba(0, 26, 112, 0.25);
  :focus {
    outline: none;
  }
  cursor: pointer;
`;

const ContentContainer = styled.div<{ isOpen: boolean }>`
  background-color: white;
  padding: ${(props) => (props.isOpen ? '2rem' : 0)};
  height: ${(props) => (props.isOpen ? 'auto' : 0)};
  padding-top: 0px;
  overflow: 'hidden';
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  color: ${Colours.default};
`;

const List = styled.ul`
  list-style: none;
  li {
    &:before {
      content: '';
      display: inline-block;
      height: 1.5rem;
      width: 1.5rem;
      background-image: url(http://placehold.it/32?text=fallback); // Fallback PNG
      background-image: url(${CheckMark});
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 0.5rem;
    }
    font-weight: bold;
  }
`;

const Image = styled.img<{ isOpen: boolean }>`
  transition: transform 0.3s;
  transform: ${(props) => (props.isOpen ? `rotate(-180deg)` : '')};
`;

interface Props {
  firstName: string;
}

const Accordion: FunctionComponent<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <PressableHeader
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <Title>How does this work?</Title>
        <Image src={DownArrow} alt="accordion arrow" isOpen={isOpen} />
      </PressableHeader>
      <ContentContainer isOpen={isOpen}>
        <p>
          Members of The Intro can invite friends, family (and even exes) to add
          a contribution to their profile. {props.firstName} would like you to
          contribute!
        </p>
        <p
          className={css`
            margin-top: 1.5rem;
          `}
        >
          As well as your contribution we’ll need:
        </p>

        <List>
          <li>Your first name</li>
          <li>Your relationship to {props.firstName}</li>
          <li>A great photo</li>
        </List>
      </ContentContainer>
    </Container>
  );
};

export default Accordion;
